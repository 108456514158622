import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex, Heading, Input, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { FormStepCustom } from "@/components/2/form";
import { Button } from "@/codegen/ui/button";
import { patchHohishHohishesHohishUuidPatch } from "@/codegen/openapi/requests";
import { toaster } from "@/codegen/ui/toaster";

import { useEnrichedHohishOutletContext } from "../local-context";
import { queryClient } from "@/services/2/react-query";
import { getEnrichedHohishQueryFromNaive } from "@/services/2/hohishes/standalone-fetches";
import { UseGetEnrichedHohishKey } from "@/services/2/hohishes/queries";

const HohishSchema = z.object({
  override_first_name: z.string(),
  override_last_name: z.string(),
  override_primary_mail_nickname: z.string(),
});

type HohishSchemaType = z.output<typeof HohishSchema>;

const uxWaiter = () => new Promise(resolve => setTimeout(resolve, 300));

export const EditIdentityHohish = () => {
  const hohish = useEnrichedHohishOutletContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<HohishSchemaType>({
    resolver: zodResolver(HohishSchema),
    defaultValues: {
      override_first_name: hohish.first_name,
      override_last_name: hohish.last_name,
      override_primary_mail_nickname: hohish.primary_mail_nickname,
    },
  });

  return (
    <Stack gap={6} maxW={480}>
      <Heading size="xl">Modification de l'identité</Heading>
      <form
        onSubmit={handleSubmit(d => {
          const waiter = uxWaiter();

          patchHohishHohishesHohishUuidPatch({ body: d, path: { hohish_uuid: hohish.uuid } }).then(
            async r => {
              if (r.error) {
                throw new Error("Error from server");
              }
              // Not too fast, to let the user see the loading state.
              await waiter;

              toaster.create({
                title: `Modification réussie`,
                description: "L'identité a bien été modifiée.",
                type: "success",
              });

              queryClient.setQueryData(
                UseGetEnrichedHohishKey(hohish.uuid, ["uncached"]),
                await getEnrichedHohishQueryFromNaive(r.data)
              );

              navigate("../identity");
            }
          );
        })}
      >
        <Stack gap={6}>
          <FormStepCustom
            label="Prénom"
            input={<Input type="text" {...register("override_first_name")} />}
            error={errors.override_first_name}
          />

          <FormStepCustom
            label="Nom"
            input={<Input type="text" {...register("override_last_name")} />}
            error={errors.override_last_name}
          />

          <FormStepCustom
            label="Nickname"
            input={<Input type="text" {...register("override_primary_mail_nickname")} />}
            error={errors.override_primary_mail_nickname}
          />

          <Flex justify="flex-end" gap={4}>
            <Button variant={"outline"} loading={isSubmitting} onClick={() => navigate(-1)}>
              Annuler
            </Button>

            <Button loading={isSubmitting} type="submit">
              Modifier l'identité
            </Button>
          </Flex>
        </Stack>
      </form>
    </Stack>
  );
};
