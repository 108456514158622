export const UsersSubapp = () => {
  return (
    <div style={{ backgroundColor: "#FFFAA0", padding: "8rem", fontSize: "1.2rem" }}>
      <p>
        Cette section a été migrée vers <a href="/2/hohishes">"le nouveau DSIT"</a>.
      </p>
      <p>
        La manière d'affecter un poste et un territoire à un collaborateur y a légèrement évolué
        pour mieux suivre l'évolution des affectations et du staffing de nos agences. Tout y est (en
        théorie) assez intuitif, mais si besoin{" "}
        <a href="https://drive.google.com/file/d/1CMdqZlozTW3hvg0PjfpSx_P1bXmXx32k/view">
          on vous explique tout dans cette vidéo
        </a>{" "}
        et <a href="https://ouihelp.slite.com/app/docs/vFJzfJc1y5oZir">dans cette fiche Slite</a>.
      </p>
      <p>
        Si vous rencontrez malgré tout des difficultés, n'hésitez pas à envoyer un mail à{" "}
        <a href="mailto:bugs@ouihelp.fr">bugs@ouihelp.fr</a>.
      </p>
    </div>
  );
};
