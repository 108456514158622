// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  boomBoomGet,
  createPostingHohishesHohishUuidPostingsPost,
  deleteAgencyGeographiesAgenciesAgencyUuidDelete,
  deletePostingHohishesHohishUuidPostingsPostingUuidDelete,
  deleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete,
  getAgenciesGeographiesAgenciesGet,
  getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet,
  getDepartmentsGeographiesDepartmentsGet,
  getHohishesHohishesGet,
  getHohishHohishesHohishUuidGet,
  getJobsHohishesJobsGet,
  getLegalEntityLegalEntitiesGet,
  getPostalCodesGeographiesPostalCodesGet,
  getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet,
  getPostingsHohishesHohishUuidPostingsGet,
  getRegionsGeographiesRegionsGet,
  getUrbanAreasGeographiesUrbanAreasGet,
  getZonesGeographiesZonesGet,
  patchHohishHohishesHohishUuidPatch,
  postAgencyGeographiesAgenciesPost,
  postUrbanAreaGeographiesUrbanAreasPost,
  putAgencyGeographiesAgenciesAgencyUuidPut,
  putUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut,
  statusDbStatusDbGet,
  statusStatusGet,
  updatePostingHohishesHohishUuidPostingsPostingUuidPost,
} from "../requests/services.gen";
import {
  BoomBoomGetError,
  CreatePostingHohishesHohishUuidPostingsPostData,
  CreatePostingHohishesHohishUuidPostingsPostError,
  DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData,
  DeleteAgencyGeographiesAgenciesAgencyUuidDeleteError,
  DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteData,
  DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteError,
  DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteData,
  DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteError,
  GetAgenciesGeographiesAgenciesGetData,
  GetAgenciesGeographiesAgenciesGetError,
  GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetError,
  GetDepartmentsGeographiesDepartmentsGetError,
  GetHohishesHohishesGetData,
  GetHohishesHohishesGetError,
  GetHohishHohishesHohishUuidGetData,
  GetHohishHohishesHohishUuidGetError,
  GetJobsHohishesJobsGetError,
  GetLegalEntityLegalEntitiesGetError,
  GetPostalCodesGeographiesPostalCodesGetError,
  GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetError,
  GetPostingsHohishesHohishUuidPostingsGetData,
  GetPostingsHohishesHohishUuidPostingsGetError,
  GetRegionsGeographiesRegionsGetData,
  GetRegionsGeographiesRegionsGetError,
  GetUrbanAreasGeographiesUrbanAreasGetData,
  GetUrbanAreasGeographiesUrbanAreasGetError,
  GetZonesGeographiesZonesGetData,
  GetZonesGeographiesZonesGetError,
  PatchHohishHohishesHohishUuidPatchData,
  PatchHohishHohishesHohishUuidPatchError,
  PostAgencyGeographiesAgenciesPostData,
  PostAgencyGeographiesAgenciesPostError,
  PostUrbanAreaGeographiesUrbanAreasPostData,
  PostUrbanAreaGeographiesUrbanAreasPostError,
  PutAgencyGeographiesAgenciesAgencyUuidPutData,
  PutAgencyGeographiesAgenciesAgencyUuidPutError,
  PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutData,
  PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutError,
  StatusDbStatusDbGetError,
  StatusStatusGetError,
  UpdatePostingHohishesHohishUuidPostingsPostingUuidPostData,
  UpdatePostingHohishesHohishUuidPostingsPostingUuidPostError,
} from "../requests/types.gen";
import * as Common from "./common";
export const useGetDepartmentsGeographiesDepartmentsGet = <
  TData = Common.GetDepartmentsGeographiesDepartmentsGetDefaultResponse,
  TError = GetDepartmentsGeographiesDepartmentsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetDepartmentsGeographiesDepartmentsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getDepartmentsGeographiesDepartmentsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet = <
  TData = Common.GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse,
  TError = GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKeyFn(
      clientOptions,
      queryKey
    ),
    queryFn: () =>
      getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet = <
  TData = Common.GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse,
  TError = GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey:
      Common.UseGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKeyFn(
        clientOptions,
        queryKey
      ),
    queryFn: () =>
      getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet({
        ...clientOptions,
      }).then(response => response.data as TData) as TData,
    ...options,
  });
export const useGetPostalCodesGeographiesPostalCodesGet = <
  TData = Common.GetPostalCodesGeographiesPostalCodesGetDefaultResponse,
  TError = GetPostalCodesGeographiesPostalCodesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetPostalCodesGeographiesPostalCodesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getPostalCodesGeographiesPostalCodesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetRegionsGeographiesRegionsGet = <
  TData = Common.GetRegionsGeographiesRegionsGetDefaultResponse,
  TError = GetRegionsGeographiesRegionsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetRegionsGeographiesRegionsGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetRegionsGeographiesRegionsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getRegionsGeographiesRegionsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetZonesGeographiesZonesGet = <
  TData = Common.GetZonesGeographiesZonesGetDefaultResponse,
  TError = GetZonesGeographiesZonesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetZonesGeographiesZonesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetZonesGeographiesZonesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getZonesGeographiesZonesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetAgenciesGeographiesAgenciesGet = <
  TData = Common.GetAgenciesGeographiesAgenciesGetDefaultResponse,
  TError = GetAgenciesGeographiesAgenciesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetAgenciesGeographiesAgenciesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetAgenciesGeographiesAgenciesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getAgenciesGeographiesAgenciesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetUrbanAreasGeographiesUrbanAreasGet = <
  TData = Common.GetUrbanAreasGeographiesUrbanAreasGetDefaultResponse,
  TError = GetUrbanAreasGeographiesUrbanAreasGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetUrbanAreasGeographiesUrbanAreasGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetUrbanAreasGeographiesUrbanAreasGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getUrbanAreasGeographiesUrbanAreasGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetJobsHohishesJobsGet = <
  TData = Common.GetJobsHohishesJobsGetDefaultResponse,
  TError = GetJobsHohishesJobsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetJobsHohishesJobsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getJobsHohishesJobsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetHohishesHohishesGet = <
  TData = Common.GetHohishesHohishesGetDefaultResponse,
  TError = GetHohishesHohishesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetHohishesHohishesGetData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetHohishesHohishesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getHohishesHohishesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetHohishHohishesHohishUuidGet = <
  TData = Common.GetHohishHohishesHohishUuidGetDefaultResponse,
  TError = GetHohishHohishesHohishUuidGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetHohishHohishesHohishUuidGetData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetHohishHohishesHohishUuidGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getHohishHohishesHohishUuidGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetPostingsHohishesHohishUuidPostingsGet = <
  TData = Common.GetPostingsHohishesHohishUuidPostingsGetDefaultResponse,
  TError = GetPostingsHohishesHohishUuidPostingsGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<GetPostingsHohishesHohishUuidPostingsGetData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetPostingsHohishesHohishUuidPostingsGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getPostingsHohishesHohishUuidPostingsGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetLegalEntityLegalEntitiesGet = <
  TData = Common.GetLegalEntityLegalEntitiesGetDefaultResponse,
  TError = GetLegalEntityLegalEntitiesGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetLegalEntityLegalEntitiesGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getLegalEntityLegalEntitiesGet({ ...clientOptions }).then(
        response => response.data as TData
      ) as TData,
    ...options,
  });
export const useStatusStatusGet = <
  TData = Common.StatusStatusGetDefaultResponse,
  TError = StatusStatusGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatusStatusGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statusStatusGet({ ...clientOptions }).then(response => response.data as TData) as TData,
    ...options,
  });
export const useStatusDbStatusDbGet = <
  TData = Common.StatusDbStatusDbGetDefaultResponse,
  TError = StatusDbStatusDbGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatusDbStatusDbGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statusDbStatusDbGet({ ...clientOptions }).then(response => response.data as TData) as TData,
    ...options,
  });
export const useBoomBoomGet = <
  TData = Common.BoomBoomGetDefaultResponse,
  TError = BoomBoomGetError,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseBoomBoomGetKeyFn(clientOptions, queryKey),
    queryFn: () =>
      boomBoomGet({ ...clientOptions }).then(response => response.data as TData) as TData,
    ...options,
  });
export const usePostAgencyGeographiesAgenciesPost = <
  TData = Common.PostAgencyGeographiesAgenciesPostMutationResult,
  TError = PostAgencyGeographiesAgenciesPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PostAgencyGeographiesAgenciesPostData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PostAgencyGeographiesAgenciesPostData, true>, TContext>({
    mutationKey: Common.UsePostAgencyGeographiesAgenciesPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      postAgencyGeographiesAgenciesPost(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePostUrbanAreaGeographiesUrbanAreasPost = <
  TData = Common.PostUrbanAreaGeographiesUrbanAreasPostMutationResult,
  TError = PostUrbanAreaGeographiesUrbanAreasPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PostUrbanAreaGeographiesUrbanAreasPostData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PostUrbanAreaGeographiesUrbanAreasPostData, true>, TContext>({
    mutationKey: Common.UsePostUrbanAreaGeographiesUrbanAreasPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      postUrbanAreaGeographiesUrbanAreasPost(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCreatePostingHohishesHohishUuidPostingsPost = <
  TData = Common.CreatePostingHohishesHohishUuidPostingsPostMutationResult,
  TError = CreatePostingHohishesHohishUuidPostingsPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<CreatePostingHohishesHohishUuidPostingsPostData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<CreatePostingHohishesHohishUuidPostingsPostData, true>,
    TContext
  >({
    mutationKey: Common.UseCreatePostingHohishesHohishUuidPostingsPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      createPostingHohishesHohishUuidPostingsPost(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUpdatePostingHohishesHohishUuidPostingsPostingUuidPost = <
  TData = Common.UpdatePostingHohishesHohishUuidPostingsPostingUuidPostMutationResult,
  TError = UpdatePostingHohishesHohishUuidPostingsPostingUuidPostError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<UpdatePostingHohishesHohishUuidPostingsPostingUuidPostData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<UpdatePostingHohishesHohishUuidPostingsPostingUuidPostData, true>,
    TContext
  >({
    mutationKey: Common.UseUpdatePostingHohishesHohishUuidPostingsPostingUuidPostKeyFn(mutationKey),
    mutationFn: clientOptions =>
      updatePostingHohishesHohishUuidPostingsPostingUuidPost(
        clientOptions
      ) as unknown as Promise<TData>,
    ...options,
  });
export const usePutAgencyGeographiesAgenciesAgencyUuidPut = <
  TData = Common.PutAgencyGeographiesAgenciesAgencyUuidPutMutationResult,
  TError = PutAgencyGeographiesAgenciesAgencyUuidPutError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PutAgencyGeographiesAgenciesAgencyUuidPutData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<PutAgencyGeographiesAgenciesAgencyUuidPutData, true>,
    TContext
  >({
    mutationKey: Common.UsePutAgencyGeographiesAgenciesAgencyUuidPutKeyFn(mutationKey),
    mutationFn: clientOptions =>
      putAgencyGeographiesAgenciesAgencyUuidPut(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut = <
  TData = Common.PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutMutationResult,
  TError = PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutData, true>,
    TContext
  >({
    mutationKey: Common.UsePutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutKeyFn(mutationKey),
    mutationFn: clientOptions =>
      putUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePatchHohishHohishesHohishUuidPatch = <
  TData = Common.PatchHohishHohishesHohishUuidPatchMutationResult,
  TError = PatchHohishHohishesHohishUuidPatchError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<PatchHohishHohishesHohishUuidPatchData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PatchHohishHohishesHohishUuidPatchData, true>, TContext>({
    mutationKey: Common.UsePatchHohishHohishesHohishUuidPatchKeyFn(mutationKey),
    mutationFn: clientOptions =>
      patchHohishHohishesHohishUuidPatch(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useDeleteAgencyGeographiesAgenciesAgencyUuidDelete = <
  TData = Common.DeleteAgencyGeographiesAgenciesAgencyUuidDeleteMutationResult,
  TError = DeleteAgencyGeographiesAgenciesAgencyUuidDeleteError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<DeleteAgencyGeographiesAgenciesAgencyUuidDeleteData, true>,
    TContext
  >({
    mutationKey: Common.UseDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKeyFn(mutationKey),
    mutationFn: clientOptions =>
      deleteAgencyGeographiesAgenciesAgencyUuidDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useDeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete = <
  TData = Common.DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteMutationResult,
  TError = DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteData, true>,
    TContext
  >({
    mutationKey:
      Common.UseDeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteKeyFn(mutationKey),
    mutationFn: clientOptions =>
      deleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete(
        clientOptions
      ) as unknown as Promise<TData>,
    ...options,
  });
export const useDeletePostingHohishesHohishUuidPostingsPostingUuidDelete = <
  TData = Common.DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteMutationResult,
  TError = DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteData, true>,
      TContext
    >,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<
    TData,
    TError,
    Options<DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteData, true>,
    TContext
  >({
    mutationKey:
      Common.UseDeletePostingHohishesHohishUuidPostingsPostingUuidDeleteKeyFn(mutationKey),
    mutationFn: clientOptions =>
      deletePostingHohishesHohishUuidPostingsPostingUuidDelete(
        clientOptions
      ) as unknown as Promise<TData>,
    ...options,
  });
