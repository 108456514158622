import "typeface-roboto-mono";

import { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router";

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { Shell as Shell2, ShellWithSidebar as Shell2WithSidebar } from "./components/2/Shell";
import {
  AgencyType,
  AppConfig,
  JobType,
  staticDataPromiseResolver,
  UrbanAreaType,
} from "./components/app-config";
import { Shell } from "./components/Shell";

import { improvedFetch, improvedFetch2 } from "./components/fetch";
import { Microsoft } from "./subapps/2/microsoft";
import { Hohishes } from "./subapps/2/hohishes";
import { Onboarding } from "./subapps/2/onboarding";
import { ViewHohish } from "./subapps/2/hohishes/view";
import { FinancialHelpTable } from "./subapps/financial-help/financial-help-table";
import { CreateFinancialHelp } from "./subapps/financial-help/create-financial-help";
import { Regions } from "./subapps/2/geographies/regions";
import { Zones } from "./subapps/2/geographies/zones";
import { Agencies } from "./subapps/2/geographies/agencies";
import { WaitingForNigol } from "./subapps/loginish";
import { PermerSubapp } from "./subapps/permer";
import { PostalCodesSubapp } from "./subapps/postal-codes";
import { UsersSubapp } from "./subapps/users";
import { UrbanAreasAndAgenciesSubapp } from "./subapps/urban-areas-and-agencies";
import { OUIHELP_LEGAL_ENTITY_UUID } from "./components/known-legal-entity-uuids";
import { SingleLayoutHohish } from "./subapps/2/hohishes/single-layout";
import { IdentityHohish } from "./subapps/2/hohishes/identity/index";
import { EditIdentityHohish } from "./subapps/2/hohishes/identity/edit";
import { PostingsHohish } from "./subapps/2/hohishes/postings/index";
import { CreatePostingHohish, EditPostingHohish } from "./subapps/2/hohishes/postings/create-edit";
import { ReactQueryWrapper } from "./components/2/react-query";

const AppInner = () => {
  useEffect(() => {
    const jobsPromise = improvedFetch(`/static-jobs`).then(async jobsResponse => {
      const jobs: JobType[] = await jobsResponse.json();
      return jobs;
    });

    const urbanAreasPromise = improvedFetch2(
      `/2/geographies/urban-areas?legal_entity_uuids=${OUIHELP_LEGAL_ENTITY_UUID}`
    ).then(async urbanAreasResponse => {
      const urbanAreas: UrbanAreaType[] = await urbanAreasResponse.json();
      return urbanAreas;
    });

    const agenciesPromise = improvedFetch2(
      `/2/geographies/agencies?legal_entity_uuids=${OUIHELP_LEGAL_ENTITY_UUID}`
    ).then(async agenciesResponse => {
      const agencies: AgencyType[] = await agenciesResponse.json();
      return agencies;
    });

    Promise.all([jobsPromise, urbanAreasPromise, agenciesPromise]).then(
      ([jobs, urbanAreas, agencies]) => {
        AppConfig.jobs = jobs;
        AppConfig.urbanAreas = urbanAreas;
        AppConfig.agencies = agencies;
        AppConfig.agenciesOptions = agencies.map(a => ({
          value: a.agency_uuid,
          label: a.agency_name,
          assignable: true,
        }));
        staticDataPromiseResolver();
      }
    );
  }, []);

  return (
    <ReactQueryWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/2" element={<Shell2 />}>
            <Route path="onboarding" element={<Onboarding />} />
            <Route path="" element={<Shell2WithSidebar />}>
              <Route path="hohishes" element={<Hohishes />} />
              <Route path="hohishes/:hohishUuid" element={<SingleLayoutHohish />}>
                <Route index element={<ViewHohish />} />
                <Route path="identity" element={<IdentityHohish />} />
                <Route path="identity/edit" element={<EditIdentityHohish />} />
                <Route path="postings" element={<PostingsHohish />} />
                <Route path="postings/create" element={<CreatePostingHohish />} />
                <Route path="postings/:postingUuid/edit" element={<EditPostingHohish />} />
              </Route>
              <Route path="geographies/regions" element={<Regions />} />
              <Route path="geographies/zones" element={<Zones />} />
              <Route path="geographies/agencies" element={<Agencies />} />
              <Route path="dsi/microsoft" element={<Microsoft />} />
            </Route>
          </Route>

          <Route path="/" element={<Shell />}>
            <Route path="users" element={<UsersSubapp />} />
            <Route path="urban-areas-and-agencies" element={<UrbanAreasAndAgenciesSubapp />} />
            <Route path="financial-helps" element={<FinancialHelpTable />} />
            <Route path="financial-helps/create" element={<CreateFinancialHelp />} />
            <Route path="postal-codes" element={<PostalCodesSubapp />} />
            <Route path="permer" element={<PermerSubapp />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReactQueryWrapper>
  );
};

// Convulted way to wait for the active account to be set before
// rendering the app.
//
// See also:
//     https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6989#issuecomment-2624355010
const OhActiveAccountTemplate = ({ children }: { children: React.ReactNode }) => {
  const { instance } = useMsal();
  const [activeAccount, setActiveAccount] = useState(() => instance.getActiveAccount());

  useEffect(() => {
    let isMounted = true;
    const refreshActiveAccount = async () => {
      const currentActiveAccount = instance.getActiveAccount();
      setActiveAccount(currentActiveAccount);

      // If we don't have an active account yet, we loop.
      if (!currentActiveAccount) {
        await new Promise(resolve => setTimeout(resolve, 50));
        if (isMounted) refreshActiveAccount();
      }
    };

    // Start the loop
    refreshActiveAccount();

    return () => {
      isMounted = false;
    };
  }, [instance]);

  return activeAccount ? <>{children}</> : null;
};

export const AppRoot = () => (
  <MsalProvider instance={AppConfig.publicClientApplication}>
    <UnauthenticatedTemplate>
      <WaitingForNigol />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <OhActiveAccountTemplate>
        <AppInner />
      </OhActiveAccountTemplate>
    </AuthenticatedTemplate>
  </MsalProvider>
);
