import { Link, Heading, Stack, Box, Flex, createListCollection, Input } from "@chakra-ui/react";
import { createColumnHelper, Row } from "@tanstack/react-table";
import { useContext, useState } from "react";

import { LegalEntityName } from "@/components/2/legal-entity";
import { Territories } from "@/components/2/geo";
import { LegalEntityContext } from "@/components/2/legal-entity-context";
import { TableLayout } from "@/components/2/layouts/table";
import {
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/codegen/ui/select";
import { JobName } from "@/components/2/hohishes";
import { useResizeObserver } from "@/hooks/use-resize-observer";
import { useGetEnrichedHohishes } from "@/services/2/hohishes/queries";
import { EnrichedHohishType } from "@/services/2/hohishes/types";

const columnHelper = createColumnHelper<EnrichedHohishType>();

const columns = [
  columnHelper.accessor("legal_entity_uuid", {
    header: "Entité",
    cell: ({ cell }) => <LegalEntityName legalEntityUuid={cell.getValue()} />,
    size: 150,
  }),
  columnHelper.accessor(row => `${row.first_name} ${row.last_name}`, {
    id: "full_name",
    header: "Prénom & Nom",
    cell: ({ cell, row }) => (
      <Link variant="underline" colorPalette={"blue"} href={`/2/hohishes/${row.original.uuid}`}>
        {cell.getValue()}
      </Link>
    ),
    minSize: 250,
  }),
  columnHelper.accessor("job2", {
    header: "Poste",
    cell: ({ cell }) => <JobName job={cell.getValue()} />,
    minSize: 280,
  }),
  columnHelper.accessor("territoryUuids", {
    header: "Territoire(s)",
    cell: ({ cell }) => <Territories territoryUuids={cell.getValue()} />,
    enableSorting: false,
  }),
  columnHelper.accessor("first_day_date", { header: "Premier jour", size: 120 }),
  columnHelper.accessor("last_day_date", { header: "Dernier jour", size: 120 }),
];

const hohishStatusesCollection = createListCollection({
  items: [
    { label: "Tous les collaborateurs", value: "", realValue: undefined },
    { label: "Collaborateurs actuels", value: "false", realValue: false },
    { label: "Collaborateurs partis", value: "true", realValue: true },
  ],
});
const hohishStatusesCollectionMap = Object.fromEntries(
  hohishStatusesCollection.items.map(e => [e.value, e.realValue])
);

const globalFilterFn = (
  row: Row<EnrichedHohishType>,
  columnId: string,
  filterValue: string
): boolean => {
  // Job2 case
  if (columnId === "job2" && row.original.job_label) {
    return row.original.job_label.toLowerCase().includes(filterValue.toLowerCase());
  }

  // Territories case
  if (columnId === "territoryUuids" && row.original.territoryLabels) {
    return row.original.territoryLabels.some(label =>
      label.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  // Global case
  const value = row.getValue(columnId);
  if (typeof value === "string") {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  }

  return false;
};

const csvMetadata = {
  filename: "collaborateurs.csv",
  columns: [
    { header: "UUID", getter: (row: EnrichedHohishType) => row.uuid },
    { header: "Entité légale", getter: (row: EnrichedHohishType) => row.legal_entity_uuid },
    { header: "Prénom", getter: (row: EnrichedHohishType) => row.first_name },
    { header: "Nom", getter: (row: EnrichedHohishType) => row.last_name },
    { header: "Mail", getter: (row: EnrichedHohishType) => row.primary_mail_address },
    { header: "Job", getter: (row: EnrichedHohishType) => row.job2 || "" },
    { header: "Premier jour", getter: (row: EnrichedHohishType) => row.first_day_date },
    { header: "Dernier jour", getter: (row: EnrichedHohishType) => row.last_day_date || "" },
    { header: "Manager UUID", getter: (row: EnrichedHohishType) => row.referent_uuid || "" },
    {
      header: "Manager Mail",
      getter: (row: EnrichedHohishType) => row.referent_primary_mail_address || "",
    },
  ],
};

export const Hohishes = () => {
  const legalEntityContext = useContext(LegalEntityContext);
  const [hohishStatuses, setHohishStatuses] = useState("");
  const [hohishSearchText, setHohishSearchText] = useState("");
  const [ref, rect] = useResizeObserver();

  const { data } = useGetEnrichedHohishes(
    { query: { legal_entity_uuids: legalEntityContext ? [legalEntityContext] : null } },
    ["uncached"],
    { placeholderData: [] }
  );

  const showingLastDayDateIsPertinent = hohishStatusesCollectionMap[hohishStatuses] !== false;
  const showingLastDayDate =
    showingLastDayDateIsPertinent && rect?.width !== undefined && rect.width >= 1200;

  const filteredData = (data as EnrichedHohishType[]).filter(
    h =>
      hohishStatusesCollectionMap[hohishStatuses] === undefined ||
      h.gone === hohishStatusesCollectionMap[hohishStatuses]
  );

  return (
    <Stack gap={0}>
      <Box p={8} backgroundColor={"gray.100"}>
        <Heading size="2xl">Collaborateurs</Heading>
      </Box>

      <Box p={8}>
        <Stack gap={4}>
          <Flex justify="space-between" gap={4}>
            <SelectRoot
              collection={hohishStatusesCollection}
              value={[hohishStatuses]}
              onValueChange={e => setHohishStatuses(e.value[0])}
              maxW={280}
              gap={0}
            >
              <SelectLabel />
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent>
                {hohishStatusesCollection.items.map(e => (
                  <SelectItem item={e} key={e.value}>
                    {e.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>

            <Input
              placeholder="Recherche sur le prénom, nom ou poste"
              value={hohishSearchText}
              onChange={e => setHohishSearchText(e.target.value)}
            />
          </Flex>

          <TableLayout
            data={filteredData}
            columns={columns}
            withPagination
            withGlobalFilter
            globalFilter={hohishSearchText}
            globalFilterFn={globalFilterFn}
            columnVisibility={{ last_day_date: showingLastDayDate }}
            csvMetadata={csvMetadata}
            tableRootRef={ref}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
