import { useContext } from "react";
import { Heading, Stack, Box } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

import { useGetZonesGeographiesZonesGet } from "@/codegen/openapi/queries";
import { ZoneType } from "@/codegen/openapi/requests/types.gen";
import { LegalEntityName } from "@/components/2/legal-entity";
import { RegionName } from "@/components/2/geo";
import { LegalEntityContext } from "@/components/2/legal-entity-context";
import { TableLayout } from "@/components/2/layouts/table";

const columnHelper = createColumnHelper<ZoneType>();

const columns = [
  columnHelper.accessor("legal_entity_uuid", {
    header: "Entité",
    cell: ({ cell }) => <LegalEntityName legalEntityUuid={cell.getValue()} />,
    minSize: 150,
  }),
  columnHelper.accessor("name", { header: "Nom" }),
  columnHelper.accessor("region_uuid", {
    header: "Région",
    cell: ({ cell }) => <RegionName regionUuid={cell.getValue()} />,
    minSize: 300,
  }),
];

export const Zones = () => {
  const legalEntityContext = useContext(LegalEntityContext);

  const { data } = useGetZonesGeographiesZonesGet(
    { query: { legal_entity_uuids: legalEntityContext ? [legalEntityContext] : null } },
    ["uncached", legalEntityContext || "all"],
    { placeholderData: [] }
  );

  return (
    <Stack>
      <Box p={8} backgroundColor={"gray.100"}>
        <Heading size="2xl">Zones</Heading>
      </Box>

      <Box p={8}>
        <TableLayout data={data as ZoneType[]} columns={columns} />
      </Box>
    </Stack>
  );
};
