import { useState } from "react";
import * as z from "zod";
import isDate from "validator/es/lib/isDate";
import { Link as RouterLink, useNavigate, useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { AsyncSelect, Select } from "chakra-react-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaExternalLinkAlt } from "react-icons/fa";

import { Flex, Heading, Input, Stack, Alert, Link as ChakraLink } from "@chakra-ui/react";

import { Button } from "@/codegen/ui/button";
import {
  createPostingHohishesHohishUuidPostingsPost,
  deletePostingHohishesHohishUuidPostingsPostingUuidDelete,
  updatePostingHohishesHohishUuidPostingsPostingUuidPost,
} from "@/codegen/openapi/requests";
import { useEnrichedHohishOutletContext } from "../local-context";
import { FormStepCustom } from "@/components/2/form";

import { AppConfig, JobType } from "@/components/app-config";
import { getJobGroups } from "@/services/2/hohishes/standalone-fetches";
import { AsyncTerritoriesSelect, TerritoryUuidsType } from "@/components/2/fields/geo";
import { useTerritoriesPromise } from "@/services/2/geo/standalone-fetches";
import {
  UseGetEnrichedHohishKey,
  useGetEnrichedHohishPostings,
} from "@/services/2/hohishes/queries";
import { EnrichedHohishPostingType } from "@/services/2/hohishes/types";
import { toaster } from "@/codegen/ui/toaster";

import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from "@/codegen/ui/dialog";
import { queryClient } from "@/services/2/react-query";

const PostingSchema = z.object({
  type: z.enum(["permanent", "backup"]),
  job: z.string(),
  starts_on: z
    .string()
    .refine((val: string) => isDate(val, { format: "YYYY-MM-DD", strictMode: true })),
  ends_on: z.preprocess(
    val => (val === "" ? null : val),
    z
      .string()
      .nullable()
      .refine(
        (val: string | null) => !val || isDate(val, { format: "YYYY-MM-DD", strictMode: true })
      )
  ),
  territories: z.object({
    agency_uuids: z.array(z.string()),
    zone_uuids: z.array(z.string()),
    region_uuids: z.array(z.string()),
  }),
});

const uxWaiter = () => new Promise(resolve => setTimeout(resolve, 300));

type PostingSchemaType = z.output<typeof PostingSchema>;

type GroupedJobOptionsType = {
  label: string;
  options: JobType[];
};

type PostingTypeType = {
  label: string;
  value: string;
};
const postingTypes = [
  { label: "Permanente", value: "permanent" },
  { label: "Backup", value: "backup" },
];

const getGroupedJobOptions = async (): Promise<GroupedJobOptionsType[]> => {
  const jobGroups = await getJobGroups();
  return jobGroups.map(group => ({
    label: group.label,
    options: group.jobs,
  }));
};

const CreateEditPostingHohish = ({
  existingPosting,
}: {
  existingPosting?: EnrichedHohishPostingType;
}) => {
  const hohish = useEnrichedHohishOutletContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PostingSchemaType>({
    resolver: zodResolver(PostingSchema),
    defaultValues: {
      type: existingPosting?.type || "permanent",
      job: existingPosting?.job,
      starts_on: existingPosting?.starts_on,
      ends_on: existingPosting?.ends_on,
      territories: {
        agency_uuids: existingPosting?.agency_uuids || [],
        zone_uuids: existingPosting?.zone_uuids || [],
        region_uuids: existingPosting?.region_uuids || [],
      },
    },
  });
  const territoriesPromise = useTerritoriesPromise(hohish.legal_entity_uuid);
  const [isDeletingDialogOpen, setIsDeletingDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const actionLabel = existingPosting ? "Modification" : "Création";

  return (
    <>
      <Stack gap={6} maxW={480}>
        <Heading size="xl">{actionLabel} d'une affectation</Heading>

        <form
          onSubmit={handleSubmit(d => {
            const { territories, ...baseBody } = d;
            const body = {
              ...baseBody,
              agency_uuids: territories.agency_uuids,
              zone_uuids: territories.zone_uuids,
              region_uuids: territories.region_uuids,
            };
            const path = { hohish_uuid: hohish.uuid };

            const waiter = uxWaiter();

            return (
              existingPosting
                ? updatePostingHohishesHohishUuidPostingsPostingUuidPost({
                    body,
                    path: { ...path, posting_uuid: existingPosting.uuid },
                  })
                : createPostingHohishesHohishUuidPostingsPost({ body, path })
            )
              .then(r => {
                if (r.response.status >= 400) {
                  const overlappingPostingsError = r.error?.detail?.find(
                    d => d.type === "overlapping_postings"
                  );
                  if (overlappingPostingsError) {
                    setError("root.serverOverlappingPostings", {
                      type: "serverOverlappingPostings",
                      message: overlappingPostingsError.msg,
                    });
                  } else {
                    setError("root.server", {
                      type: "server",
                      message: `Server answered ${r.response.status}`,
                    });
                  }
                  return { type: "error" };
                }
                return { type: "success" };
              })
              .then(async ({ type }) => {
                if (type === "success") {
                  // Not too fast, to let the user see the loading state.
                  await waiter;

                  toaster.create({
                    title: `${actionLabel} réussie`,
                    description: "L'affectation a bien été enregistrée.",
                    type: "success",
                  });

                  queryClient.removeQueries({
                    queryKey: UseGetEnrichedHohishKey(hohish.uuid, ["uncached"]),
                  });

                  navigate("../postings");
                }
              });
          })}
        >
          <Stack gap={6}>
            <FormStepCustom
              label="Type"
              input={
                <Controller
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      options={postingTypes}
                      ref={ref}
                      onChange={(option: PostingTypeType | null) =>
                        onChange(option ? option.value : null)
                      }
                      onBlur={onBlur}
                      value={postingTypes.find(option => option.value === value) || null}
                    />
                  )}
                  name="type"
                  control={control}
                />
              }
              error={errors.type}
              helpText={
                <>
                  Un collaborateur ne peut avoir qu'une seule affectation permanente sur une période
                  donnée. Il peut en revanche assurer un ou plusieurs backups en parallèle.
                </>
              }
            />

            <FormStepCustom
              label="Job / Équipe"
              input={
                <Controller
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncSelect
                      isClearable={true}
                      defaultOptions
                      loadOptions={async (inputValue: string) => {
                        const groupedJobOptions = await getGroupedJobOptions();
                        return groupedJobOptions.map(group => ({
                          ...group,
                          options: group.options.filter(
                            p =>
                              !inputValue || !p.label.search(new RegExp(`^.*${inputValue}.*$`, "i"))
                          ),
                        }));
                      }}
                      ref={ref}
                      onChange={(option: JobType | null) => onChange(option ? option.value : null)}
                      onBlur={onBlur}
                      value={
                        AppConfig.jobs && value
                          ? AppConfig.jobs.find(option => option.value === value)
                          : null
                      }
                    />
                  )}
                  name="job"
                  control={control}
                />
              }
              error={errors.job}
            />

            <FormStepCustom
              label="Premier jour"
              input={<Input type="date" {...register("starts_on")} />}
              error={errors.starts_on}
            />

            <FormStepCustom
              label="Dernier jour"
              input={<Input type="date" {...register("ends_on")} />}
              error={errors.ends_on}
              helpText="Ce champs reste généralement vide, sauf dans le cas des affectations temporaires (eg: les backups et les collaborateurs itinérants)."
            />

            <FormStepCustom
              label="Territoire(s)"
              input={
                <Controller
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncTerritoriesSelect
                      selectableTerritoriesPromise={territoriesPromise}
                      refCallback={ref}
                      onChange={(v: TerritoryUuidsType | null) => onChange(v)}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                  name="territories"
                  control={control}
                />
              }
              error={errors.territories}
              helpText={
                <>
                  <b>Attention</b>, il s'agit du <b>territoire de référence</b> du collaborateur{" "}
                  <i style={{ fontStyle: "italic" }}>(parfois mais rarement "des" territoires)</i>.
                  Il ne s'agit pas des territoires auxquels le collaborateur doit avoir accès sur
                  Hassibot.
                </>
              }
            />

            {errors.root?.serverOverlappingPostings && (
              <Alert.Root status="error">
                <Alert.Indicator />
                <Alert.Content>
                  <Alert.Title>Affectation en conflit</Alert.Title>
                  <Alert.Description>
                    Enregistrement impossible : les dates d'une autre affectation entrerait en
                    conflit avec celles-ci.{" "}
                    <RouterLink
                      to={`../postings/${errors.root!.serverOverlappingPostings.message!}/edit`}
                      target="_blank"
                    >
                      <ChakraLink variant="underline" colorPalette={"blue"} as="span">
                        Modifiez l'autre affectation{" "}
                        <FaExternalLinkAlt style={{ display: "inline", fontSize: "75%" }} />
                      </ChakraLink>
                    </RouterLink>{" "}
                    avant de continuer ici.
                  </Alert.Description>
                </Alert.Content>
              </Alert.Root>
            )}

            <Flex justify="flex-end" gap={4}>
              <Button
                variant={"outline"}
                loading={isSubmitting || isDeleting}
                onClick={() => navigate(-1)}
              >
                Annuler
              </Button>
              {existingPosting && (
                <Button
                  loading={isSubmitting || isDeleting}
                  variant={"outline"}
                  color={"red"}
                  onClick={() => setIsDeletingDialogOpen(true)}
                >
                  Supprimer l'affectation
                </Button>
              )}
              <Button loading={isSubmitting || isDeleting} type="submit">
                {existingPosting ? "Modifier" : "Créer"} l'affectation
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>

      <DialogRoot
        role="alertdialog"
        open={isDeletingDialogOpen}
        closeOnInteractOutside={true}
        onOpenChange={({ open }) => setIsDeletingDialogOpen(open)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Suppression de l'affectation</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <p>Vous allez supprimer l'affectation, confirmez-vous ?</p>
          </DialogBody>
          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button variant="outline">Annuler</Button>
            </DialogActionTrigger>
            <Button
              colorPalette="red"
              onClick={async () => {
                setIsDeleting(true);
                const waiter = uxWaiter();
                await deletePostingHohishesHohishUuidPostingsPostingUuidDelete({
                  path: { hohish_uuid: hohish.uuid, posting_uuid: existingPosting!.uuid },
                });
                // Not too fast, to let the user see the loading state.
                await waiter;
                navigate(-1);
              }}
            >
              Supprimer l'affectation
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export const CreatePostingHohish = CreateEditPostingHohish;
export const EditPostingHohish = () => {
  const { postingUuid } = useParams();
  const hohish = useEnrichedHohishOutletContext();
  const { data } = useGetEnrichedHohishPostings(
    { path: { hohish_uuid: hohish.uuid } },
    ["uncached"],
    {}
  );
  if (!data) {
    return null;
  }

  const posting = (data as EnrichedHohishPostingType[]).find(p => p.uuid === postingUuid);
  if (!posting) {
    return null;
  }

  return <CreateEditPostingHohish existingPosting={posting} />;
};
