import { type Options } from "@hey-api/client-fetch";

import {
  getHohishesHohishesGet,
  GetHohishesHohishesGetData,
  getHohishHohishesHohishUuidGet,
  GetHohishHohishesHohishUuidGetData,
  getJobsHohishesJobsGet,
  getPostingsHohishesHohishUuidPostingsGet,
  GetPostingsHohishesHohishUuidPostingsGetData,
  HohishType,
  JobType,
} from "@/codegen/openapi/requests";
import { UseGetJobsHohishesJobsGetKeyFn } from "@/codegen/openapi/queries";

import { TWENTY_FOUR_STALE_WHILE_REVALIDATE, queryClient } from "../react-query";
import { EnrichedHohishPostingType, EnrichedHohishType } from "./types";
import { getTerritories } from "../geo/standalone-fetches";
import { TerritoriesType } from "../geo/types";

// Jobs
export const getJobsQueryFn = (): Promise<JobType[]> =>
  getJobsHohishesJobsGet({}).then(response => response.data as JobType[]);

export const getJobs = () =>
  queryClient.fetchQuery({
    queryKey: UseGetJobsHohishesJobsGetKeyFn(),
    queryFn: getJobsQueryFn,
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
  });

interface JobGroup {
  label: string;
  jobs: JobType[];
}

const groupOrder: Record<any, number> = {
  Ops: 0,
  Sales: 1,
  Autres: 2,
};

export const getJobGroups = (): Promise<JobGroup[]> =>
  getJobs().then(jobs => {
    const jobGroups: Record<string, JobGroup> = {};
    jobs.forEach(job => {
      if (!job.assignable) {
        return;
      }

      if (!jobGroups[job.group]) {
        jobGroups[job.group] = {
          label: job.group,
          jobs: [],
        };
      }

      jobGroups[job.group].jobs.push(job);
    });

    return Object.values(jobGroups).sort((a, b) => groupOrder[a.label] - groupOrder[b.label]);
  });

const extractTerritoryLabels = (
  territoryUuids: string[],
  territories: TerritoriesType
): string[] => {
  const agencies = territories.agencies.filter(agency => territoryUuids.includes(agency.uuid));
  const zones = territories.zones.filter(zone => territoryUuids.includes(zone.uuid));
  const regions = territories.regions.filter(region => territoryUuids.includes(region.uuid));

  return [
    ...agencies.map(agency => agency.name),
    ...zones.map(zone => zone.name),
    ...regions.map(region => region.name),
  ];
};

// Multiple hohishes
export const getEnrichedHohishesQueryFn = async (
  clientOptions: Options<GetHohishesHohishesGetData, true>
): Promise<EnrichedHohishType[]> => {
  const naive = await getHohishesHohishesGet({ ...clientOptions }).then(response => response.data);
  const territories = await getTerritories();
  return naive.map(n => {
    const territoryUuids = ([] as string[]).concat(n.agency_uuids2, n.zone_uuids2, n.region_uuids2);
    return {
      ...n,
      territoryUuids,
      territoryLabels: extractTerritoryLabels(territoryUuids, territories),
    };
  });
};

// Single hohish
export const getEnrichedHohishQueryFromNaive = async (
  naive: HohishType
): Promise<EnrichedHohishType> => {
  const territories = await getTerritories();
  const territoryUuids = ([] as string[]).concat(
    naive.agency_uuids2,
    naive.zone_uuids2,
    naive.region_uuids2
  );
  return {
    ...naive,
    territoryUuids,
    territoryLabels: extractTerritoryLabels(territoryUuids, territories),
  };
};

export const getEnrichedHohishQueryFn = async (
  clientOptions: Options<GetHohishHohishesHohishUuidGetData, true>
): Promise<EnrichedHohishType> => {
  const naive = await getHohishHohishesHohishUuidGet({ ...clientOptions }).then(
    response => response.data
  );
  return getEnrichedHohishQueryFromNaive(naive);
};

export const getEnrichedHohishPostingsQueryFn = async (
  clientOptions: Options<GetPostingsHohishesHohishUuidPostingsGetData, true>
): Promise<EnrichedHohishPostingType[]> => {
  const naive = await getPostingsHohishesHohishUuidPostingsGet({ ...clientOptions }).then(
    response => response.data
  );
  return naive.map(n => ({
    ...n,
    territoryUuids: ([] as string[]).concat(n.agency_uuids, n.zone_uuids, n.region_uuids),
  }));
};
