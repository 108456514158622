// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import { UseQueryResult } from "@tanstack/react-query";
import {
  boomBoomGet,
  createPostingHohishesHohishUuidPostingsPost,
  deleteAgencyGeographiesAgenciesAgencyUuidDelete,
  deletePostingHohishesHohishUuidPostingsPostingUuidDelete,
  deleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete,
  getAgenciesGeographiesAgenciesGet,
  getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet,
  getDepartmentsGeographiesDepartmentsGet,
  getHohishesHohishesGet,
  getHohishHohishesHohishUuidGet,
  getJobsHohishesJobsGet,
  getLegalEntityLegalEntitiesGet,
  getPostalCodesGeographiesPostalCodesGet,
  getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet,
  getPostingsHohishesHohishUuidPostingsGet,
  getRegionsGeographiesRegionsGet,
  getUrbanAreasGeographiesUrbanAreasGet,
  getZonesGeographiesZonesGet,
  patchHohishHohishesHohishUuidPatch,
  postAgencyGeographiesAgenciesPost,
  postUrbanAreaGeographiesUrbanAreasPost,
  putAgencyGeographiesAgenciesAgencyUuidPut,
  putUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut,
  statusDbStatusDbGet,
  statusStatusGet,
  updatePostingHohishesHohishUuidPostingsPostingUuidPost,
} from "../requests/services.gen";
export type GetDepartmentsGeographiesDepartmentsGetDefaultResponse = Awaited<
  ReturnType<typeof getDepartmentsGeographiesDepartmentsGet>
>["data"];
export type GetDepartmentsGeographiesDepartmentsGetQueryResult<
  TData = GetDepartmentsGeographiesDepartmentsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetDepartmentsGeographiesDepartmentsGetKey =
  "GetDepartmentsGeographiesDepartmentsGet";
export const UseGetDepartmentsGeographiesDepartmentsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetDepartmentsGeographiesDepartmentsGetKey, ...(queryKey ?? [clientOptions])];
export type GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse =
  Awaited<
    ReturnType<typeof getPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet>
  >["data"];
export type GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetQueryResult<
  TData = GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKey =
  "GetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGet";
export const UseGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [
  useGetPostalCodesWithAgenciesGeographiesPostalCodesWithAgenciesGetKey,
  ...(queryKey ?? [clientOptions]),
];
export type GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse =
  Awaited<
    ReturnType<typeof getAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet>
  >["data"];
export type GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetQueryResult<
  TData = GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKey =
  "GetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGet";
export const UseGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [
  useGetAgenciesPostalCodesBindingsGeographiesAgenciesPostalCodesBindingsGetKey,
  ...(queryKey ?? [clientOptions]),
];
export type GetPostalCodesGeographiesPostalCodesGetDefaultResponse = Awaited<
  ReturnType<typeof getPostalCodesGeographiesPostalCodesGet>
>["data"];
export type GetPostalCodesGeographiesPostalCodesGetQueryResult<
  TData = GetPostalCodesGeographiesPostalCodesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetPostalCodesGeographiesPostalCodesGetKey =
  "GetPostalCodesGeographiesPostalCodesGet";
export const UseGetPostalCodesGeographiesPostalCodesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetPostalCodesGeographiesPostalCodesGetKey, ...(queryKey ?? [clientOptions])];
export type GetRegionsGeographiesRegionsGetDefaultResponse = Awaited<
  ReturnType<typeof getRegionsGeographiesRegionsGet>
>["data"];
export type GetRegionsGeographiesRegionsGetQueryResult<
  TData = GetRegionsGeographiesRegionsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetRegionsGeographiesRegionsGetKey = "GetRegionsGeographiesRegionsGet";
export const UseGetRegionsGeographiesRegionsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetRegionsGeographiesRegionsGetKey, ...(queryKey ?? [clientOptions])];
export type GetZonesGeographiesZonesGetDefaultResponse = Awaited<
  ReturnType<typeof getZonesGeographiesZonesGet>
>["data"];
export type GetZonesGeographiesZonesGetQueryResult<
  TData = GetZonesGeographiesZonesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetZonesGeographiesZonesGetKey = "GetZonesGeographiesZonesGet";
export const UseGetZonesGeographiesZonesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetZonesGeographiesZonesGetKey, ...(queryKey ?? [clientOptions])];
export type GetAgenciesGeographiesAgenciesGetDefaultResponse = Awaited<
  ReturnType<typeof getAgenciesGeographiesAgenciesGet>
>["data"];
export type GetAgenciesGeographiesAgenciesGetQueryResult<
  TData = GetAgenciesGeographiesAgenciesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetAgenciesGeographiesAgenciesGetKey = "GetAgenciesGeographiesAgenciesGet";
export const UseGetAgenciesGeographiesAgenciesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetAgenciesGeographiesAgenciesGetKey, ...(queryKey ?? [clientOptions])];
export type GetUrbanAreasGeographiesUrbanAreasGetDefaultResponse = Awaited<
  ReturnType<typeof getUrbanAreasGeographiesUrbanAreasGet>
>["data"];
export type GetUrbanAreasGeographiesUrbanAreasGetQueryResult<
  TData = GetUrbanAreasGeographiesUrbanAreasGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetUrbanAreasGeographiesUrbanAreasGetKey = "GetUrbanAreasGeographiesUrbanAreasGet";
export const UseGetUrbanAreasGeographiesUrbanAreasGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetUrbanAreasGeographiesUrbanAreasGetKey, ...(queryKey ?? [clientOptions])];
export type GetJobsHohishesJobsGetDefaultResponse = Awaited<
  ReturnType<typeof getJobsHohishesJobsGet>
>["data"];
export type GetJobsHohishesJobsGetQueryResult<
  TData = GetJobsHohishesJobsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetJobsHohishesJobsGetKey = "GetJobsHohishesJobsGet";
export const UseGetJobsHohishesJobsGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetJobsHohishesJobsGetKey, ...(queryKey ?? [clientOptions])];
export type GetHohishesHohishesGetDefaultResponse = Awaited<
  ReturnType<typeof getHohishesHohishesGet>
>["data"];
export type GetHohishesHohishesGetQueryResult<
  TData = GetHohishesHohishesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetHohishesHohishesGetKey = "GetHohishesHohishesGet";
export const UseGetHohishesHohishesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetHohishesHohishesGetKey, ...(queryKey ?? [clientOptions])];
export type GetHohishHohishesHohishUuidGetDefaultResponse = Awaited<
  ReturnType<typeof getHohishHohishesHohishUuidGet>
>["data"];
export type GetHohishHohishesHohishUuidGetQueryResult<
  TData = GetHohishHohishesHohishUuidGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetHohishHohishesHohishUuidGetKey = "GetHohishHohishesHohishUuidGet";
export const UseGetHohishHohishesHohishUuidGetKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useGetHohishHohishesHohishUuidGetKey, ...(queryKey ?? [clientOptions])];
export type GetPostingsHohishesHohishUuidPostingsGetDefaultResponse = Awaited<
  ReturnType<typeof getPostingsHohishesHohishUuidPostingsGet>
>["data"];
export type GetPostingsHohishesHohishUuidPostingsGetQueryResult<
  TData = GetPostingsHohishesHohishUuidPostingsGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetPostingsHohishesHohishUuidPostingsGetKey =
  "GetPostingsHohishesHohishUuidPostingsGet";
export const UseGetPostingsHohishesHohishUuidPostingsGetKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useGetPostingsHohishesHohishUuidPostingsGetKey, ...(queryKey ?? [clientOptions])];
export type GetLegalEntityLegalEntitiesGetDefaultResponse = Awaited<
  ReturnType<typeof getLegalEntityLegalEntitiesGet>
>["data"];
export type GetLegalEntityLegalEntitiesGetQueryResult<
  TData = GetLegalEntityLegalEntitiesGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useGetLegalEntityLegalEntitiesGetKey = "GetLegalEntityLegalEntitiesGet";
export const UseGetLegalEntityLegalEntitiesGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useGetLegalEntityLegalEntitiesGetKey, ...(queryKey ?? [clientOptions])];
export type StatusStatusGetDefaultResponse = Awaited<ReturnType<typeof statusStatusGet>>["data"];
export type StatusStatusGetQueryResult<
  TData = StatusStatusGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatusStatusGetKey = "StatusStatusGet";
export const UseStatusStatusGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useStatusStatusGetKey, ...(queryKey ?? [clientOptions])];
export type StatusDbStatusDbGetDefaultResponse = Awaited<
  ReturnType<typeof statusDbStatusDbGet>
>["data"];
export type StatusDbStatusDbGetQueryResult<
  TData = StatusDbStatusDbGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatusDbStatusDbGetKey = "StatusDbStatusDbGet";
export const UseStatusDbStatusDbGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useStatusDbStatusDbGetKey, ...(queryKey ?? [clientOptions])];
export type BoomBoomGetDefaultResponse = Awaited<ReturnType<typeof boomBoomGet>>["data"];
export type BoomBoomGetQueryResult<
  TData = BoomBoomGetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useBoomBoomGetKey = "BoomBoomGet";
export const UseBoomBoomGetKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useBoomBoomGetKey, ...(queryKey ?? [clientOptions])];
export type PostAgencyGeographiesAgenciesPostMutationResult = Awaited<
  ReturnType<typeof postAgencyGeographiesAgenciesPost>
>;
export const usePostAgencyGeographiesAgenciesPostKey = "PostAgencyGeographiesAgenciesPost";
export const UsePostAgencyGeographiesAgenciesPostKeyFn = (mutationKey?: Array<unknown>) => [
  usePostAgencyGeographiesAgenciesPostKey,
  ...(mutationKey ?? []),
];
export type PostUrbanAreaGeographiesUrbanAreasPostMutationResult = Awaited<
  ReturnType<typeof postUrbanAreaGeographiesUrbanAreasPost>
>;
export const usePostUrbanAreaGeographiesUrbanAreasPostKey =
  "PostUrbanAreaGeographiesUrbanAreasPost";
export const UsePostUrbanAreaGeographiesUrbanAreasPostKeyFn = (mutationKey?: Array<unknown>) => [
  usePostUrbanAreaGeographiesUrbanAreasPostKey,
  ...(mutationKey ?? []),
];
export type CreatePostingHohishesHohishUuidPostingsPostMutationResult = Awaited<
  ReturnType<typeof createPostingHohishesHohishUuidPostingsPost>
>;
export const useCreatePostingHohishesHohishUuidPostingsPostKey =
  "CreatePostingHohishesHohishUuidPostingsPost";
export const UseCreatePostingHohishesHohishUuidPostingsPostKeyFn = (
  mutationKey?: Array<unknown>
) => [useCreatePostingHohishesHohishUuidPostingsPostKey, ...(mutationKey ?? [])];
export type UpdatePostingHohishesHohishUuidPostingsPostingUuidPostMutationResult = Awaited<
  ReturnType<typeof updatePostingHohishesHohishUuidPostingsPostingUuidPost>
>;
export const useUpdatePostingHohishesHohishUuidPostingsPostingUuidPostKey =
  "UpdatePostingHohishesHohishUuidPostingsPostingUuidPost";
export const UseUpdatePostingHohishesHohishUuidPostingsPostingUuidPostKeyFn = (
  mutationKey?: Array<unknown>
) => [useUpdatePostingHohishesHohishUuidPostingsPostingUuidPostKey, ...(mutationKey ?? [])];
export type PutAgencyGeographiesAgenciesAgencyUuidPutMutationResult = Awaited<
  ReturnType<typeof putAgencyGeographiesAgenciesAgencyUuidPut>
>;
export const usePutAgencyGeographiesAgenciesAgencyUuidPutKey =
  "PutAgencyGeographiesAgenciesAgencyUuidPut";
export const UsePutAgencyGeographiesAgenciesAgencyUuidPutKeyFn = (mutationKey?: Array<unknown>) => [
  usePutAgencyGeographiesAgenciesAgencyUuidPutKey,
  ...(mutationKey ?? []),
];
export type PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutMutationResult = Awaited<
  ReturnType<typeof putUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut>
>;
export const usePutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutKey =
  "PutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPut";
export const UsePutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutKeyFn = (
  mutationKey?: Array<unknown>
) => [usePutUrbanAreaGeographiesUrbanAreasUrbanAreaSlugPutKey, ...(mutationKey ?? [])];
export type PatchHohishHohishesHohishUuidPatchMutationResult = Awaited<
  ReturnType<typeof patchHohishHohishesHohishUuidPatch>
>;
export const usePatchHohishHohishesHohishUuidPatchKey = "PatchHohishHohishesHohishUuidPatch";
export const UsePatchHohishHohishesHohishUuidPatchKeyFn = (mutationKey?: Array<unknown>) => [
  usePatchHohishHohishesHohishUuidPatchKey,
  ...(mutationKey ?? []),
];
export type DeleteAgencyGeographiesAgenciesAgencyUuidDeleteMutationResult = Awaited<
  ReturnType<typeof deleteAgencyGeographiesAgenciesAgencyUuidDelete>
>;
export const useDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKey =
  "DeleteAgencyGeographiesAgenciesAgencyUuidDelete";
export const UseDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKeyFn = (
  mutationKey?: Array<unknown>
) => [useDeleteAgencyGeographiesAgenciesAgencyUuidDeleteKey, ...(mutationKey ?? [])];
export type DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteMutationResult = Awaited<
  ReturnType<typeof deleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete>
>;
export const useDeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteKey =
  "DeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDelete";
export const UseDeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteKeyFn = (
  mutationKey?: Array<unknown>
) => [useDeleteUrbanAreaGeographiesUrbanAreasUrbanAreaSlugDeleteKey, ...(mutationKey ?? [])];
export type DeletePostingHohishesHohishUuidPostingsPostingUuidDeleteMutationResult = Awaited<
  ReturnType<typeof deletePostingHohishesHohishUuidPostingsPostingUuidDelete>
>;
export const useDeletePostingHohishesHohishUuidPostingsPostingUuidDeleteKey =
  "DeletePostingHohishesHohishUuidPostingsPostingUuidDelete";
export const UseDeletePostingHohishesHohishUuidPostingsPostingUuidDeleteKeyFn = (
  mutationKey?: Array<unknown>
) => [useDeletePostingHohishesHohishUuidPostingsPostingUuidDeleteKey, ...(mutationKey ?? [])];
