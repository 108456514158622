import { TerritoriesType } from "@/services/2/geo/types";
import { AsyncSelect, MultiValue } from "chakra-react-select";
import { RefCallBack } from "react-hook-form";

import { TerritoryName } from "../geo";

export type TerritoryUuidsType = {
  agency_uuids: string[];
  zone_uuids: string[];
  region_uuids: string[];
};

type LocalOptionType = {
  value: string;
  type: "agency" | "zone" | "region";
};
type GroupedLocalOptionType = {
  label: string;
  options: LocalOptionType[];
};

export const AsyncTerritoriesSelect = ({
  selectableTerritoriesPromise,
  refCallback,
  value,
  onBlur,
  onChange,
}: {
  selectableTerritoriesPromise: Promise<TerritoriesType>;
  refCallback: RefCallBack;
  value: TerritoryUuidsType;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChange: (newValue: TerritoryUuidsType) => void;
}) => {
  return (
    <AsyncSelect
      isClearable
      defaultOptions
      formatOptionLabel={(option: LocalOptionType) => (
        <TerritoryName territoryUuid={option.value} />
      )}
      loadOptions={async (inputValue: string) => {
        const territories = await selectableTerritoriesPromise;
        const searchRegExp = new RegExp(`^.*${inputValue}.*$`, "i");
        return [
          {
            label: "Agences",
            options: territories.agencies
              .filter(p => !inputValue || !p.name.search(searchRegExp))
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .map(p => ({ value: p.uuid, type: "agency" })),
          },
          {
            label: "Zones",
            options: territories.zones
              .filter(p => !inputValue || !p.name.search(searchRegExp))
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .map(p => ({ value: p.uuid, type: "zone" })),
          },
          {
            label: "Régions",
            options: territories.regions
              .filter(p => !inputValue || !p.name.search(searchRegExp))
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .map(p => ({ value: p.uuid, type: "region" })),
          },
        ] as GroupedLocalOptionType[];
      }}
      ref={refCallback}
      onChange={(newValue: MultiValue<LocalOptionType>) => {
        onChange({
          agency_uuids: newValue.filter(a => a.type === "agency").map(a => a.value),
          zone_uuids: newValue.filter(a => a.type === "zone").map(a => a.value),
          region_uuids: newValue.filter(a => a.type === "region").map(a => a.value),
        });
      }}
      onBlur={onBlur}
      value={([] as LocalOptionType[]).concat(
        value.agency_uuids.map(uuid => ({ value: uuid, type: "agency" })),
        value.zone_uuids.map(uuid => ({ value: uuid, type: "zone" })),
        value.region_uuids.map(uuid => ({ value: uuid, type: "region" }))
      )}
      isMulti
    />
  );
};
